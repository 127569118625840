:root {
  --azul: #2991D6;
  --vermelho: #DC3545;
  --verde: #2FAF9F;
}
*{
  box-sizing: border-box!important;
}
.card-padrao {
  width: 100%;
  padding: 5px;
  position: relative;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
}

.container-modal-cadastros {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px 0;
}

.box-modal-cadastros {
  width: 720px;
  padding: 10px;
  background: #fff;
  border: none !important;
  border-radius: 5px;
}

.body-modal-cadastros {
  width: 100%;
  margin-top: 10px;
  padding: 5px;
}

.footer-modal-cadastros {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}

.groupInputsChamado {
  padding: 5px;
  font-size: 14px;
}

.groupInputsChamado span {
  font-weight: bold;
}

.lateralCards {
  width: 5px;
  background: var(--azul);
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

::-webkit-scrollbar {
  width: 0px;
  background: #eee;
}

:-webkit-scrollbar-thumb {
  background: #eee;
}

.container-card-dash {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
}

.header-container-dash {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  box-sizing: border-box;
  padding-top: 5px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.header-container-dash h6 {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 12px;
  font-weight: 600;
  color: #343a40;
  padding: 0;
  text-align: left
}

.body-card-dash {
  width: 100%;
  height: 300px;
  display: flex;
  overflow: hidden;
}

.control-card-dash {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
}

.line-item-card-list-info small {
  color: #A9AEB8;
}

.line-item-card-list-info h6, .line-item-card-list-info h4 {
  color: #1a1a1a;
}

.line-item-card-list-info h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.arrowIconList {
  color: var(--azul);
  cursor: pointer;
}

.body-to-table-dash {
  width: 100%;
}

.table-dash {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table-dash th {
  border-top: 0;
  border-bottom: 0;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #343a40;
  letter-spacing: 0.5px;
}

.pd-20px {
  padding-left: 20px !important;
}

.table-dash.verbas th:nth-child(even) {
  padding-left: 20px !important;
}

.table-dash.verbas td:nth-child(even) {
  padding-left: 20px !important;
}

.table-dash td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  cursor: pointer;
  transition: all .4s;
}

.spin180 {
  animation-name: spin180;
  animation-duration: .4s;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 0;
}

.wd-10p {
  width: 10%;
}

.table-dash th {
  padding: 0.75rem;
  vertical-align: top;
}

.efectLines {
  animation-name: lines;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 0;
  transition: all 1s;
}

/* #438496 */

.card-list-info {
  width: 359px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 10px 0px 10px;
  margin-right: 10px;
  padding-bottom: 0;
}

.card-list-info:nth-child(even) {
  border-top: 4px solid var(--azul)
}

.card-list-info:nth-child(odd) {
  border-top: 4px solid #32b0ff;
}

.item-card-list-info {
  width: 100%;
  padding : 10px;
}

.item-card-list-info h5 {
  background: var(--azul);
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}

.line-item-card-list-info.footer {
  justify-content: center;
  border-radius: 5px;
  padding: 5px;
}

.line-item-card-list-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.subtitleApp {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.subtitleApp.f12 {
  font-size: 12px;
  text-transform: uppercase;
}

.card-list-info h5.title {
  text-align: center;
  padding: 10px 0 10px;
  font-size: 16px;
  letter-spacing: .45px;
}
.groupInputsChamado label{
  font-size: 12px !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
}

@keyframes spin180 {
  from {
    transform: rotate(0);
    transition: all .4s;
  }
  to {
    transform: rotate(180deg);
    transition: all .4s;
  }
}

@keyframes lines {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes message {
  from {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.noMargin {
  margin: 0 !important;
}

@media (min-width: 320px) and (max-width: 990px) {
  .card-list-info {
    margin-right: 0;
  }
}